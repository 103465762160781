<template>
  <section v-if="data" class="o-steps-default">
    <div class="container">
      <div class="o-steps-default__container">
        <div class="o-steps-default__info">
          <div class="o-steps-default__header">
            <h2 class="o-steps-default__title">{{ data.title }}</h2>
            <h3 v-if="data.subtitle" class="o-steps-default__subtitle">{{ data.subtitle }}</h3>
          </div>
          <div v-if="data.button" class="o-steps-default__button">
            <Button
              v-if="data.button.type === 'dialog'"
              modifier="tertiary"
              theme="light"
              @click="requireForm(formList[data.button.value])"
            >
              {{ data.button.title }}
            </Button>

            <Button
              v-if="data.button.type === 'node'"
              :href="data.button.value"
              modifier="primary"
              tag="a"
            >
              {{ data.button.title }}
            </Button>

            <Button
              v-if="data.button.type === 'absolute'"
              :href="data.button.value"
              modifier="primary"
              tag="a"
              target="_blank"
            >
              {{ data.button.title }}
            </Button>
          </div>
        </div>
        <div class="o-steps-default__cards">
          <Card
            v-for="element in data.items"
            css-class="m-card-info"
            :theme="element.dark ? 'dark' : undefined"
          >
            <template #contentHeader>
              {{ element.title }}
            </template>

            <template v-if="element.description || element.subtitle" #content>
              <div v-if="element.subtitle" class="m-card-info__content-headline">
                {{ element.subtitle }}
              </div>
              <template v-if="element.description">{{ element.description }}</template>
            </template>

            <template v-if="element.tags.length" #contentFooter>
              <Tag v-for="tag in element.tags" :theme="element.dark ? 'dark' : 'light'">{{
                tag.title
              }}</Tag>
            </template>
          </Card>
        </div>
      </div>
    </div>
  </section>

  <section v-else-if="!websiteConfig.modes.isProduction">
    <UtilsDataMissing :data="props.data" />
  </section>
</template>

<script setup lang="ts">
// Vue
import { ref } from 'vue';

// Components
import UtilsDataMissing from '~/components/Utils/Data/Missing/UtilsDataMissing.vue';
import Button from '@ice-products-ui/vue-library/Button';
import Tag from '@ice-products-ui/vue-library/Tag';
import Card from '@ice-products-ui/vue-library/Card';

// Composables
import useGetData from '~/composables/api/useGetData';
import useForms from '~/composables/molecules/useForms';
import useComponentSchemaValidator from '~/composables/validators/useComponentSchemaValidator';

// Types
import type { IComponentProps } from '~/typings/types/component.types';
import type { TOStepsDefault } from '~/components/O/Steps/Default/OStepsDefault.types';
import type { TWebsiteConfig } from '~/typings/types/website-config.types';

// Schema
import schema from '~/components/O/Steps/Default/OStepsDefault.schema';

defineOptions({
  name: 'OStepsDefault',
});

const props = withDefaults(defineProps<IComponentProps<TOStepsDefault>>(), {
  viewName: 'OStepsDefault',
});

const websiteConfig = useState<TWebsiteConfig>('config');

const { requireForm, formList } = useForms();
const { getComponent } = useGetData<TOStepsDefault>();

const data = ref<TOStepsDefault>(getComponent(props).component);

useComponentSchemaValidator(schema, props);
</script>
